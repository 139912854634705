import './style.css';

// import normalizeWheel from 'normalize-wheel-es';

import { gsap, random } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
// import { Observer } from 'gsap/Observer';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);
// gsap.registerPlugin(Observer);

// import data from '../src/data.js';

import axios from 'axios';

import Marquee3k from 'marquee3000';

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////
/// GLOBAL VARS AND UTILS
//////////////////////////////////////////////////////////////////

console.log('hit');

var body = document.body;

var show_grid = false;

// toggle show / hide grid
const grid = document.querySelector('.grid');
if (grid && !show_grid) {
    grid.classList.add('hide');
}
function toggleGrid() {
    if (grid.classList.contains('hide')) {
        grid.classList.remove('hide');
    } else {
        grid.classList.add('hide');
    }
}

document.onkeyup = (e) => {
    if (e.keyCode == 71) {
        toggleGrid();
    }
};

///

//////////////////////////////////////////////////////////////////
//////// API
//////////////////////////////////////////////////////////////////

var active_tags_arr = [];

function getData() {
    var location = window.location.pathname;
    var urlquery = window.location.search;
    if (urlquery) {
        var urlparams = new URLSearchParams(urlquery);
    }

    if (location == '/' || location == '/index.html') {
        console.log('is home');

        getAllThemes('index');
    } else if (location == '/single-theme.html') {
        console.log('is single theme');
        getTheme(parseInt(urlparams.get('id')), 'theme');

        getRelatedTheme(parseInt(urlparams.get('id')));
    } else if (location == '/single-experiment.html') {
        getExperiment(parseInt(urlparams.get('id')));
    } else if (location == '/experiments.html') {
        getAllExperiments(window.location.search);

        getTags('experiments', urlparams ? urlparams.getAll('tag') : null);
    } else if (location == '/themes.html') {
        getAllThemes('theme', window.location.search);
        console.log('all themes page');

        getTags('themes', urlparams ? urlparams.getAll('tag') : null);
    } else if (location == '/about.html') {
        console.log('is about page');
        initM3000();
    }
}

function getAllThemes(page, filter) {
    console.log('fetching themes');
    axios
        .get('https://test.lab.plus-ex.com/api/themes', {
            // hashId: vrhi,
            // invalid: true,
        })
        .then(function (response) {
            if (response.status == 200) {
                // console.log('submitted 200 response', response.status, response);

                // succss
                if (filter) {
                    console.log('filtered themes', filter, response.data);

                    var urlparams = new URLSearchParams(filter);

                    var active_tag_arr = urlparams.getAll('tag');

                    // filter theme data
                    var filtered_data = [];
                    for (let i = 0; i < response.data.length; i++) {
                        const _theme = response.data[i];

                        for (let y = 0; y < _theme.experiments.length; y++) {
                            const _experiment = _theme.experiments[y];

                            for (let z = 0; z < _experiment.tags.length; z++) {
                                const _tag = _experiment.tags[z];

                                for (let vv = 0; vv < active_tag_arr.length; vv++) {
                                    // console.log(active_tag_arr[vv], _tag.id);

                                    // if tags in each exp match active tag, push theme to filtered array
                                    if (active_tag_arr[vv] == _tag.id) {
                                        filtered_data.push(_theme);
                                    }
                                }
                            }
                        }
                    }

                    // remove duplicates from filtered array
                    filtered_data = [...new Set(filtered_data)];

                    var data_to_use = filtered_data;
                } else {
                    console.log('unfiltered themes', response.data);
                    var data_to_use = response.data;
                }

                if (page == 'index') {
                    populateIndex(response.data);
                } else {
                    populateThemes(data_to_use);
                }
            } else {
                console.log('case3');
                console.log('submit else response', response.status, response);
            }
        })
        .catch(function (error) {
            console.log('case4');
            console.log('submit error', error);
        })
        .then(function () {
            // always executed
            // console.log('submit CHECK', has_applied);
        });
}

function getTheme(idx, page) {
    console.log('fetching single theme');
    axios
        .get(`https://test.lab.plus-ex.com/api/theme/${idx}`, {
            // hashId: vrhi,
            // invalid: true,
        })
        .then(function (response) {
            if (response.status == 200) {
                // console.log('single theme response', response.status, response);
                // console.log('single theme', response.data.id);

                if (page == 'theme') {
                    populateSingleTheme(response.data);
                }
                if (page == 'experiment') {
                    populateRelatedExperiments(response.data);
                }
            } else {
                console.log('case3');
                console.log('submit else response', response.status, response);
            }
        })
        .catch(function (error) {
            console.log('case4');
            console.log('submit error', error);
        })
        .then(function () {
            // always executed
            // console.log('submit CHECK', has_applied);
        });
}

function getRelatedTheme(idx) {
    console.log('fetching related themes');
    axios
        .get('https://test.lab.plus-ex.com/api/themes', {
            // hashId: vrhi,
            // invalid: true,
        })
        .then(function (response) {
            if (response.status == 200) {
                // console.log('submitted 200 response', response.status, response);
                // succss
                // console.log('case1');

                console.log('related data', idx, response.data);

                var index_in_array = response.data.findIndex((theme) => theme.id == idx);

                let related_index;
                if (response.data[index_in_array + 1]) {
                    related_index = index_in_array + 1;
                } else {
                    related_index = 0;
                }

                populateRelatedTheme(response.data[related_index]);
            } else {
                console.log('case3');
                console.log('submit else response', response.status, response);
            }
        })
        .catch(function (error) {
            console.log('case4');
            console.log('submit error', error);
        })
        .then(function () {
            // always executed
            // console.log('submit CHECK', has_applied);
        });
}

function getAllExperiments(filter) {
    axios
        .get('https://test.lab.plus-ex.com/api/experiments' + (filter ? filter : ''), {
            // hashId: vrhi,
            // invalid: true,
        })
        .then(function (response) {
            if (response.status == 200) {
                console.log('submitted 200 response', response.status, response);

                // succss
                // console.log('case1');

                populateExperiments(response.data);
            } else {
                console.log('case2');
                console.log('submit else response', response.status, response);
            }
        })
        .catch(function (error) {
            console.log('case3');
            console.log('submit error', error);
        })
        .then(function () {
            // always executed
            // console.log('submit CHECK', has_applied);
        });
}

function getExperiment(idx) {
    axios
        .get(`https://test.lab.plus-ex.com/api/experiment/${idx}`, {
            // hashId: vrhi,
            // invalid: true,
        })
        .then(function (response) {
            if (response.status == 200) {
                // console.log('submitted 200 response', response.status, response);
                // console.log('case1');
                console.log('data experiment', response.data);

                // success
                populateSingleExperiment(response.data);

                // get related experiments
                getTheme(response.data.theme, 'experiment');
            } else {
                console.log('case2');
                console.log('submit else response', response.status, response);
            }
        })
        .catch(function (error) {
            console.log('case3');
            console.log('submit error', error);
        })
        .then(function () {
            // always executed
            // console.log('submit CHECK', has_applied);
        });
}

function getTags(page, tagsonload) {
    axios
        .get(`https://test.lab.plus-ex.com/api/tags`, {
            // hashId: vrhi,
            // invalid: true,
        })
        .then(function (response) {
            if (response.status == 200) {
                // console.log('submitted 200 response', response.status, response);
                // console.log('case1');

                populateTags(page, response.data);

                if (tagsonload) {
                    for (let i = 0; i < tagsonload.length; i++) {
                        // console.log('tt', tagsonload[i]);
                        document.querySelector('.tag-' + tagsonload[i]).classList.add('active');
                    }
                }
            } else {
                console.log('case2');
                // console.log('submit else response', response.status, response);
            }
        })
        .catch(function (error) {
            console.log('case3');
            console.log('submit error', error);
        })
        .then(function () {
            // always executed
            // console.log('submit CHECK', has_applied);
        });
}

//////////////////////
//////////////////////

function populateIndex(data) {
    console.log('pop index', data);

    let mainwrap = document.querySelector('.main-wrap');

    for (let i = 0; i < data.length; i++) {
        let themedata = data[i];
        let single_theme = document.createElement('div');
        single_theme.setAttribute('class', `single-theme single-theme-${themedata.id}`);
        var parsed_id = themedata.id < 10 ? '0' + themedata.id : themedata.id;
        single_theme.innerHTML = `
        <div class="ticker ticker-theme to-stick">
                <div class="ticker-inner marquee3k">
                    <div class="single-ticket-set marquee-container">
                    20220613 VOL${parsed_id}. ${themedata.name} • 20220613 VOL${parsed_id}. ${themedata.name} • 20220613 VOL${parsed_id}. ${themedata.name} • 20220613 VOL${parsed_id}. ${themedata.name} • 20220613 VOL${parsed_id}. ${themedata.name} • 20220613 VOL${parsed_id}. ${themedata.name} • 20220613 VOL${parsed_id}. ${themedata.name} • 20220724. VOL${parsed_id}. ${themedata.name} •&nbsp;
                    </div>
                </div>
        </div>
        
        <div class="gap-line red"></div>
        
        <div class="sides-wrap">

            <div class="side side-left">
                <div class="inner">
                    <h2 class="text-title">VOL${parsed_id}.<br />${themedata.name}</h2>
                    <span class="text-desc p-l">VOL${parsed_id}. ${themedata.description}</span>
                    <div class="button button-cta m-l"><a href="single-theme.html?id=${themedata.id}">VIEW '${themedata.name}' →</a></div>
                    <div class="theme-thumbnails">
                        ${themedata.experiments.reduce(
                            (updated, experiment) =>
                                updated.concat(`

                            <div class="mini-thumbnail ${experiment.video_url ? 'has-video' : ''}" data-index="${experiment.id}">
                                ${
                                    experiment.video_url
                                        ? `
                                    <img src="${experiment.video_thumbnail}" class="poster aa" />`
                                        : `
                                    <img src="${experiment.image}" />
                                    `
                                }
                            </div>
                        `),
                            ''
                        )}
                    </div>
                </div>
            </div>
            <div class="side side-right">
                ${themedata.experiments.reduce(
                    (updated, experiment) =>
                        updated.concat(`
                        <div class="thumbnail-set size-${experiment.grid} cross-padding experiment-${experiment.id}">
                            <div class="thumbnail-image ${experiment.video_url ? 'has-video' : ''}">
                                ${
                                    experiment.video_url
                                        ? `
                                    <video src="${experiment.video_url}" poster="${experiment.video_thumbnail}" autoplay muted loop playsinline></video>
                                    <img src="${experiment.video_thumbnail}" class="poster" />`
                                        : `
                                    <img src="${experiment.image}" class="poster" />
                                    `
                                }
                            </div>
                            <div class="meta-wrap">
                                <div class="flex title-wrap">
                                    <span class="text-small-title title">${experiment.title}</span>
                                    <span class="text-number">EXPERIMENT ${parsed_id}-${experiment.id < 10 ? '0' + experiment.id : experiment.id}</span>
                                </div>
                                <span class="text-desc">${experiment.description}</span>
                                <div class="tags-wrap">
                                    ${experiment.tags.reduce((updated, tag) => updated.concat(`<a href="/experiments.html?tag=${tag.id}"><div class="tag"  data-id="${tag.id}">${tag.name}</div></a>`), '')}
                                </div>
                            </div>
                            <svg class="cross t-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path stroke="#000" d="M5.25.5v10" />
                                <path stroke="#000" d="M10.25 5.5h-10" />
                            </svg>
                            <svg class="cross b-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path stroke="#000" d="M5.25.5v10" />
                                <path stroke="#000" d="M10.25 5.5h-10" />
                            </svg>
                        </div>
                `),
                    ''
                )}
            </div>

    `;

        mainwrap.appendChild(single_theme);
    }
    initScripts_Index();
}

function populateThemes(data, idx) {
    document.querySelector('.archive-grid').innerHTML = '';

    for (let i = 0; i < data.length; i++) {
        let themedata = data[i];
        let single_theme = document.createElement('div');
        var parsed_id = themedata.id < 10 ? '0' + themedata.id : themedata.id;
        single_theme.innerHTML = `
        <a href="single-theme.html?id=${themedata.id}">
            <div class="single-theme-row thumbnail-row">
                <div class="title-wrap">
                    <span class="text-small-title title">VOLUME ${parsed_id}. ${themedata.name}</span>
                    <div class="ticker-col">
                        <div class="ticker ticker-theme">
                            <div class="ticker-inner marquee3k">
                                <div class="single-ticket-set marquee-container">20221011 VOL${parsed_id}. ${themedata.name} • 20221011 VOL${parsed_id}. ${themedata.name} • 20221011 VOL${parsed_id}. ${themedata.name} • 20221011 VOL${parsed_id}. ${themedata.name} • 20221011 VOL${parsed_id}. ${themedata.name} • 20221011 VOL${parsed_id}. ${themedata.name} • 20221011 VOL${parsed_id}. ${themedata.name} • 20220724. VOL${parsed_id} ${themedata.name} •&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <span class="text-number p-r">2022-08-05</span>
                </div>
                <span class="text-desc">${themedata.description}</span>

                <div class="thumbnail-wrap thumbnail-to-tick px-ticker">
                    <div class="inner-ticker">
                        <div class="single-ticker-set">
                            ${themedata.experiments.reduce(
                                (updated, experiment) =>
                                    updated.concat(`
                                    <div class="thumbnail ${experiment.video_url ? 'has-video' : ''}">
                                        ${
                                            experiment.video_url
                                                ? `

                                            <img src="${experiment.video_thumbnail}" class="poster" />`
                                                : `
                                            <img src="${experiment.image}" class="poster" />
                                            `
                                        }
                                    </div>
    
                            `),
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </a>
        
        `;
        document.querySelector('.archive-grid').appendChild(single_theme);
    }

    initPXticker();
    initM3000();
}

function populateSingleTheme(data) {
    console.log('theme data', data);

    let themedata = data;
    // let single_theme = document.createElement('div');
    var parsed_id = themedata.id < 10 ? '0' + themedata.id : themedata.id;
    document.querySelector('.main-wrap').innerHTML = `
        <div class="ticker ticker-theme to-stick">
            <div class="ticker-inner marquee3k">
                <div class="single-ticket-set marquee-container">20220613 VOL${parsed_id}.  ${themedata.name} • 20220613 VOL${parsed_id}.  ${themedata.name} • 20220613 VOL${parsed_id}.  ${themedata.name} • 20220613 VOL${parsed_id}.  ${themedata.name} • 20220613 VOL${parsed_id}.  ${themedata.name} • 20220613 VOL${parsed_id}.  ${themedata.name} • 20220613 VOL${parsed_id}.  ${themedata.name} • 20220724. VOL01 VOL${parsed_id}.  ${themedata.name} •&nbsp;</div>
            </div>
        </div>

        <div class="theme-header">
            <div class="gap-line red"></div>
            <div class="max-width">
                <h1 class="text-title">VOL${parsed_id}.<br />  ${themedata.name}</h1>
                <div class="desc-wrap">
                    <span class="text-desc">
                        ${themedata.description}
                        <br /><br />
                    </span>
                    <span class="text-desc scroll-prompt"> Scroll down to view and explore the experiments. </span>
                </div>
            </div>
        </div>
        
        <!------->
        <div class="gap-line red"></div>
        <!------->


        ${themedata.experiments.reduce(
            (updated, experiment) =>
                updated.concat(`


                <div class="single-theme max-width">
                    <svg class="cross t-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path stroke="#000" d="M5.25.5v10" />
                        <path stroke="#000" d="M10.25 5.5h-10" />
                    </svg>

                    <div class="thumbnail-set cross-padding">
                        <div class="thumbnail-image ${experiment.video_url ? 'has-video' : ''}">
                            ${
                                experiment.video_url
                                    ? `
                                <video src="${experiment.video_url}" poster="${experiment.video_thumbnail}" autoplay muted loop playsinline></video>
                                <img src="${experiment.video_thumbnail}" class="poster" />`
                                    : `
                                <img src="${experiment.image}" />
                                `
                            }
                        </div>
                        <div class="meta-wrap p-l">
                            <div class="flex title-wrap">
                                <span class="text-medium-title title">${experiment.title}</span>
                                <span class="text-number">EXPERIMENT ${parsed_id}-${experiment.id < 10 ? '0' + experiment.id : experiment.id}</span>
                            </div>
                            <span class="text-desc">${experiment.description}</span>
                            <div class="tags-wrap">
                                ${experiment.tags.reduce((updated, tag) => updated.concat(`<a href="/experiments.html?tag=${tag.id}"><div class="tag"  data-id="${tag.id}">${tag.name}</div></a>`), '')}
                            </div>
                            <div class="button button-cta"><a href="single-experiment.html?id=${experiment.id}">VIEW EXPERIMENT</a></div>
                        </div>
                    </div>
                </div>

                <!------->
                <div class="gap-line red"></div>
                <!------->
            `),
            ''
        )}
    `;
    initM3000();
    // document.querySelector('.main-wrap').appendChild(single_theme);
}

function populateRelatedTheme(data) {
    console.log('related theme data', data);

    let themedata = data;
    let related_theme = document.querySelector('.related-themes');
    var parsed_id = themedata.id < 10 ? '0' + themedata.id : themedata.id;
    related_theme.innerHTML = `
    
    
    <div class="inner">
    <div class="gap-line red"></div>

    <div class="ticker ticker-theme ">
        <div class="ticker-inner marquee3k">
            <div class="single-ticket-set marquee-container">RELATED THEMES • RELATED THEMES • RELATED THEMES • RELATED THEMES • RELATED THEMES • RELATED THEMES • RELATED THEMES •&nbsp;</div>
        </div>
    </div>

    <svg class="cross t-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path stroke="#000" d="M5.25.5v10" />
        <path stroke="#000" d="M10.25 5.5h-10" />
    </svg>
            <a href="single-theme.html?id=${themedata.id}">
                <div class="single-theme-row thumbnail-row">
                    <div class="title-wrap">
                        <span class="text-small-title title">VOLUME ${parsed_id}. ${themedata.name}</span>
        
                        <span class="text-number p-r">2022-08-05</span>
                    </div>
                    <div class="thumbnail-wrap thumbnail-to-tick px-ticker">
                        <div class="inner-ticker">
                            <div class="single-ticker-set">
                                ${themedata.experiments.reduce(
                                    (updated, experiment) =>
                                        updated.concat(`
                                        <div class="thumbnail ${experiment.video_url ? 'has-video' : ''}">
                                            ${
                                                experiment.video_url
                                                    ? `

                                                <img src="${experiment.video_thumbnail}" class="poster" />`
                                                    : `
                                                <img src="${experiment.image}" class="poster" />
                                                `
                                            }
                                        </div>
        
                                `),
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    `;

    // document.querySelector('.main-wrap').appendChild(single_theme);

    initPXticker();
    initM3000();
}

function populateExperiments(data, idx) {
    document.querySelector('.archive-grid').innerHTML = '';
    for (let i = 0; i < data.length; i++) {
        let experiment = document.createElement('div');
        experiment.setAttribute('class', 'thumbnail-set');
        // console.log(data[i]);
        experiment.innerHTML = `
                <a href="single-experiment.html?id=${data[i].id}">
                    <div class="thumbnail-image ${data[i].video_url ? 'has-video' : ''}">
                        ${
                            data[i].video_url
                                ? `
                            <video src="${data[i].video_url}" poster="${experiment.video_thumbnail}" autoplay muted loop playsinline></video>
                            <img src="${data[i].video_thumbnail}" class="poster" />`
                                : `
                            <img src="${data[i].image}" />
                            `
                        }
                    </div>
                    <div class="meta-wrap p-l">
                        <div class="flex title-wrap">
                            <span class="text-small-title title">${data[i].title}</span>
                            <span class="text-number p-r">
                            E${data[i].theme.id < 10 ? '0' + data[i].theme.id : data[i].theme.id}-${data[i].id < 10 ? '0' + data[i].id : data[i].id}</span>
                        </div>
                    </div>
                </a>
            `;
        document.querySelector('.archive-grid').appendChild(experiment);
    }
}

function populateSingleExperiment(data, idx) {
    let parent_wrap = document.querySelector('.single-experiment');

    var redline = document.createElement('div');
    redline.setAttribute('class', 'gap-line red');

    var parsed_id = data.id < 10 ? '0' + data.id : data.id;

    /// populate top marquee
    document.querySelector('.marquee-container').innerHTML = `20220613 VOL${parsed_id}.  ${data.title} • 20220613 VOL${parsed_id}.  ${data.title} • 20220613 VOL${parsed_id}.  ${data.title} • 20220613 VOL${parsed_id}.  ${data.title} • 20220613 VOL${parsed_id}.  ${data.title} • 20220613 VOL${parsed_id}.  ${data.title} • 20220613 VOL${parsed_id}.  ${data.title} • 20220724. VOL01 VOL${parsed_id}.  ${data.title} •&nbsp;`;

    /// add sidebar content
    let exp_sidebar = document.querySelector('.main-wrap .sidebar');
    exp_sidebar.innerHTML = `
        <div class="thumbnail-wrap">
            ${data.infos.reduce(
                (arr, info) =>
                    arr.concat(`
                <div class="thumbnail">
                    <img src="${info.image ? info.image : info.video_thumbnail}" alt="" />
                </div>`),
                ''
            )}
        </div>
        <div class="meta-wrap p-l">
            <span class="text-small-title title">${data.title}</span>
            <span class="text-desc">${data.description}</span>
            <div class="tags-wrap">
                ${data.tags.reduce((updated, tag) => updated.concat(`<a href="/experiments.html?tag=${tag.id}"><div class="tag"  data-id="${tag.id}">${tag.name}</div></a>`), '')}
            </div>
        </div>

        ${
            data.link
                ? `
                <a href="${data.link}" target="_blank">
                <div class="button-border">LAUNCH GL</div>
                </a>
                `
                : ''
        }

    `;
    /// add hero set
    let exp_heroset = document.createElement('div');
    exp_heroset.setAttribute('class', 'thumbnail-set cross-padding hero-set');
    exp_heroset.setAttribute('experiment-id', data.id);
    exp_heroset.innerHTML = `
            <div class="flex title-wrap title-wrap-mobile">
                <span class="text-small-title title">${data.title}</span>
                <span class="text-number">01-${data.id}</span>
            </div>
            <div class="thumbnail-image ${data.video_url ? 'has-video' : ''}" >
                ${
                    data.video_url
                        ? `
                    <video src="${data.video_url}" poster="${data.video_thumbnail}" autoplay muted loop playsinline></video>
                    <img src="${data.video_thumbnail}" class="poster" />`
                        : `
                    <img src="${data.image}" class="poster" />
                    `
                }
            </div>
            <div class="meta-wrap p-l">
                <div class="flex title-wrap title-wrap-main">
                    <span class="text-medium-title title">${data.title}</span>
                    <span class="text-number">EXPERIMENT 01-${data.id}</span>
                </div>
                <span class="text-desc">${data.description}</span>
                <div class="tags-wrap">
                    ${data.tags.reduce((updated, tag) => updated.concat(`<a href="/experiments.html?tag=${tag.id}"><div class="tag"  data-id="${tag.id}">${tag.name}</div></a>`), '')}
                </div>

 
                
            </div>

            <svg class="cross t-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path stroke="#000" d="M5.25.5v10" />
                <path stroke="#000" d="M10.25 5.5h-10" />
            </svg>
        `;

    parent_wrap.appendChild(exp_heroset);

    // add mobile pinned launch gl
    if (data.link) {
        let exp_launchgl_mob = document.createElement('a');
        exp_launchgl_mob.setAttribute('class', 'gl-button-mobile');
        exp_launchgl_mob.setAttribute('href', 'data.link');
        exp_launchgl_mob.setAttribute('target', '_blank');
        exp_launchgl_mob.innerHTML = `<div class="button-border">LAUNCH GL</div>`;

        parent_wrap.appendChild(exp_launchgl_mob);
    }

    parent_wrap.appendChild(redline.cloneNode(true));

    // work contents
    for (let i = 0; i < data.infos.length; i++) {
        const info = data.infos[i];
        let exp_content = document.createElement('div');
        exp_content.setAttribute('class', `thumbnail-set cross-padding align-${info.location} size-${info.grid}`);
        exp_content.innerHTML = `
            <div class="thumbnail-image">
                ${
                    info.video
                        ? `
                    <video src="${info.video}" poster="${info.video_thumbnail}" autoplay muted loop playsinline></video>
                    <img src="${info.video_thumbnail}" class="poster" />`
                        : `
                    <img src="${info.image}" />
                    `
                }
            </div>
            <div class="meta-wrap">
                ${
                    info.description
                        ? `
                    <div class="text-desc text-caption"><span class="bullet">▓</span><span>${info.description}</span></div>
                        `
                        : ``
                }
            </div>

            <svg class="cross t-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path stroke="#000" d="M5.25.5v10" />
                <path stroke="#000" d="M10.25 5.5h-10" />
            </svg>
            `;

        parent_wrap.appendChild(exp_content);

        if (i != data.infos.length - 1) {
            parent_wrap.appendChild(redline.cloneNode(true));
        }
    }
}

function populateRelatedExperiments(data) {
    console.log('related experiments', data);

    let themedata = data;
    let related_experiments = document.querySelector('.related-experiments');
    var parsed_id = themedata.id < 10 ? '0' + themedata.id : themedata.id;
    related_experiments.innerHTML = `
        <div class="inner">
            <div class="ticker ticker-theme to-stick">
                <div class="ticker-inner marquee3k">
                    <div class="single-ticket-set marquee-container">RELATED EXPERIMENTS • RELATED EXPERIMENTS • RELATED EXPERIMENTS • RELATED EXPERIMENTS • RELATED EXPERIMENTS • RELATED EXPERIMENTS • RELATED EXPERIMENTS •&nbsp;</div>
                </div>
            </div>

            <svg class="cross t-l" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path stroke="#000" d="M5.25.5v10" />
                <path stroke="#000" d="M10.25 5.5h-10" />
            </svg>


            <div class="single-experiment-row thumbnail-row">

            </div>
        </div>

    `;

    for (let i = 0; i < data.experiments.length; i++) {
        if (document.querySelector('.hero-set').getAttribute('experiment-id') == data.experiments[i].id) {
            // dont show current experiment in related works
            continue;
        }

        let new_experiment = document.createElement('div');
        new_experiment.setAttribute('class', 'thumbnail-set cross-padding');
        new_experiment.innerHTML = `
            <!---->
            <div class="thumbnail-set cross-padding">
                <div class="thumbnail-image ${data.experiments[i].video_url ? 'has-video' : ''}">
                    ${
                        data.experiments[i].video_url
                            ? `
                        <video src="${data.experiments[i].video_url}" poster="${data.experiments[i].video_thumbnail}" autoplay muted loop playsinline></video>
                        <img src="${data.experiments[i].video_thumbnail}" class="poster" />`
                            : `
                        <img src="${data.experiments[i].image}" class="poster" />
                        `
                    }
                </div>
                <div class="meta-wrap p-l">
                    <div class="flex title-wrap">
                        <span class="text-small-title title">${data.experiments[i].title}</span>
                        <span class="text-number text-number-desktop">EXPERIMENT ${parsed_id}-${data.experiments[i].id}</span>
                        <span class="text-number text-number-mobile">${parsed_id}-${data.experiments[i].id < 10 ? '0' + data.experiments[i].id : data.experiments[i].id}</span>
                    </div>
                    <span class="text-desc">${data.experiments[i].description}</span>
                    <div class="tags-wrap">
                        ${data.experiments[i].tags.reduce((updated, tag) => updated.concat(`<a href="/experiments.html?tag=${tag.id}"><div class="tag"  data-id="${tag.id}">${tag.name}</div></a>`), '')}
                    </div>
                </div>
            </div>
            <!---->
        `;
        related_experiments.querySelector('.single-experiment-row').appendChild(new_experiment);
    }

    `

    `;

    // document.querySelector('.main-wrap').appendChild(single_theme);

    initM3000();
    initPXticker();
}

function populateTags(page, data) {
    const tagswrap = document.querySelector('.tags-wrap');

    // console.log('check for tags on load', window.location.search);

    tagswrap.innerHTML = `
        ${data.reduce((updated, tag) => updated.concat(`<div class="tag tag-${tag.id}" data-id="${tag.id}">${tag.name}</div>`), '')}
    `;

    for (let i = 0; i < document.querySelectorAll('.filter-menu .tag').length; i++) {
        const tag = document.querySelectorAll('.filter-menu .tag')[i];

        tag.onclick = () => {
            tag.classList.toggle('active');

            active_tags_arr = [];

            // get base url /experiments.html
            var url = new URL(window.location.origin + `/${page}.html`);

            for (let y = 0; y < document.querySelectorAll('.filter-menu .tag').length; y++) {
                // check for tags with 'active' class (use clicked tags)
                if (document.querySelectorAll('.filter-menu .tag')[y].classList.contains('active')) {
                    // if have 'active' push to array
                    active_tags_arr.push(document.querySelectorAll('.filter-menu .tag')[y].getAttribute('data-id'));
                }
            }

            for (let z = 0; z < active_tags_arr.length; z++) {
                url.searchParams.append('tag', active_tags_arr[z]);
            }

            // window.location = url;
            window.history.pushState('data', 'aa', url);

            if (active_tags_arr.length > 0) {
                console.log('refilter data');

                if (page == 'experiments') {
                    getAllExperiments(window.location.search);
                } else if (page == 'themes') {
                    getAllThemes('themes', window.location.search);
                }
            } else {
                if (page == 'experiments') {
                    getAllExperiments();
                } else {
                    getAllThemes();
                }
            }
        };
    }
}

getData();

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

function initTriggers() {
    //// pin index themes left side
    if (document.body.classList.contains('page-index')) {
        if (window.innerWidth > 560) {
            gsap.utils.toArray('.single-theme').forEach(function (section) {
                // console.log(section);
                ScrollTrigger.create({
                    trigger: section,
                    start: 'top top', // when the top of the trigger hits the top of the viewport
                    end: 'bottom bottom',
                    pin: section.querySelector('.side-left'),
                });
            });
        } else {
            // mobile only
            // pin mobile top logo on scroll
            // ScrollTrigger.create({
            //     trigger: '.menu-top',
            //     start: 'top -25px', // when the top of the trigger hits the top of the viewport
            //     endTrigger: '.footer-credits',
            //     pin: true,
            //     pinSpacing: false,
            //     markers: true,
            //     onToggle: (self) => {
            //         console.log('toggled, isActive:', self.isActive);
            //         if (self.isActive) {
            //             document.querySelector('.intro-mobile').classList.add('pinned');
            //         } else {
            //             document.querySelector('.intro-mobile').classList.remove('pinned');
            //         }
            //     },
            //     // onUpdate: (self) => {
            //     //     //   console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
            //     // },
            // });
            /////
            // on mobile, pin tickerz
            // gsap.utils.toArray('.single-theme').forEach(function (section) {
            //     // console.log(section);
            //     ScrollTrigger.create({
            //         trigger: section,
            //         start: 'top top', // when the top of the trigger hits the top of the viewport
            //         end: 'bottom bottom',
            //         pin: section.querySelector('.ticker'),
            //         // markers: true,
            //         // onToggle: (self) => {
            //         //     console.log('toggled, isActive:', section, self.isActive);
            //         // },
            //         // onUpdate: (self) => {
            //         //     //   console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
            //         // },
            //     });
            // });
        }
    }
    // pin single experiment till footer
    if (document.body.classList.contains('page-single-experiment')) {
        ScrollTrigger.create({
            trigger: '.sidebar',
            start: 'top 180px', // when the top of the trigger hits the top of the viewport
            end: 'top-=110px bottom',
            endTrigger: '.related-section',
            pin: true,
            pinSpacing: false,
            markers: true,
            onToggle: (self) => {
                console.log('toggled, isActive:');
                if (self.isActive) {
                    document.querySelector('.sidebar').classList.add('show-meta');
                } else {
                    document.querySelector('.sidebar').classList.remove('show-meta');
                }
            },
            // onUpdate: (self) => {
            //     //   console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
            // },
        });
    }
}

setTimeout(() => {
    initTriggers();
}, 600);

/////// TICKER CLASS

class Ticker {
    constructor(target_ele, speed, direction) {
        this.ticker_ele = target_ele;
        this.ticker_inner = this.ticker_ele.querySelector('.inner-ticker');
        this.ticker_set = this.ticker_ele.querySelectorAll('.single-ticker-set')[0];

        this.offset_counter = 0;
        this.ticker_set_width = 0;
        this.set_to_offset = 0;
        this.ticker_speed = speed || 1;
        this.ticker_direction = direction;
        this.ticker_direction_val = this.ticker_direction == 'right' ? 1 : -1;

        // duplicate original set to repeat anad fake infinite
        var clone = this.ticker_set.cloneNode(true);
        this.ticker_set.after(clone);
        var clone2 = this.ticker_set.cloneNode(true);
        this.ticker_set.after(clone2);

        /// create ticker
        setTimeout(() => {
            // start / resume ticker
            this.initTicker();
        }, 100);
    }
    initTicker() {
        // start ticker
        this.ticker_set_width = this.ticker_set.offsetWidth;
        // console.log(this.ticker_set);
        // console.log(this.ticker_set.getBoundingClientRect().width, this.ticker_set_width);

        if (this.ticker_direction == 'right') {
            this.offset_counter = -this.ticker_set_width;
        }

        this.tick();
    }
    tick() {
        this.offset_counter += this.ticker_speed * this.ticker_direction_val;

        this.ticker_RAF = requestAnimationFrame(() => this.tick());

        this.ticker_inner.style.transform = `translateX(${this.offset_counter}px)`;

        // if first set goes outside of window, reset ticker to make infinite
        if (this.ticker_direction == 'right') {
            if (this.offset_counter + this.ticker_speed > 0) {
                this.offset_counter = -this.ticker_set_width;
            }
        } else {
            if (this.offset_counter + this.ticker_speed < -this.ticker_set_width) {
                // console.log(this.offset_counter, this.ticker_set_width);
                this.offset_counter = 0;
            }
        }
    }
    killTicker() {
        cancelAnimationFrame(this.ticker_RAF);
    }
}

////////

var mob_menu_trigger = document.querySelector('.mobile-menu-trigger');

function toggleMobileMenu() {
    if (body.classList.contains('mob-menu-open')) {
        closeMobileMenu();
    } else {
        openMobileMenu();
    }
}
function openMobileMenu() {
    body.classList.add('mob-menu-open');
    body.classList.remove('mob-filter-open');
    body.classList.add('lock-scroll');
    if (mob_filter_trigger) {
        mob_menu_trigger.querySelector('.button-border').innerHTML = 'CLOSE';
        mob_filter_trigger.querySelector('.button-border').innerHTML = 'FILTER';
    }
}
function closeMobileMenu() {
    body.classList.remove('mob-menu-open');
    body.classList.remove('lock-scroll');
    if (mob_filter_trigger) {
        mob_menu_trigger.querySelector('.button-border').innerHTML = 'MENU';
    }
}

mob_menu_trigger.onclick = () => {
    toggleMobileMenu();
};

////////////////////////////////////////////////

//////////////// MOBILE MENU / FILTER TOGGLE

var mob_filter_trigger = document.querySelector('.filter-menu-trigger');
if (mob_filter_trigger) {
    function toggleFilterMenu() {
        if (body.classList.contains('mob-filter-open')) {
            closeFilterMenu();
        } else {
            openFilterMenu();
        }
    }
    function openFilterMenu() {
        body.classList.add('mob-filter-open');
        body.classList.remove('mob-menu-open');
        body.classList.add('lock-scroll');
        mob_filter_trigger.querySelector('.button-border').innerHTML = 'CLOSE';
        mob_menu_trigger.querySelector('.button-border').innerHTML = 'MENU';
    }
    function closeFilterMenu() {
        body.classList.remove('mob-filter-open');
        body.classList.remove('lock-scroll');
        mob_filter_trigger.querySelector('.button-border').innerHTML = 'FILTER';
    }

    mob_filter_trigger.onclick = () => {
        toggleFilterMenu();
    };
}

////////////////////////////////////////////////

function initPXticker() {
    if (window.innerWidth < 600) return;
    for (let i = 0; i < document.querySelectorAll('.px-ticker').length; i++) {
        const ticker_ele = document.querySelectorAll('.px-ticker')[i];
        var ticker = new Ticker(ticker_ele, 0.25);
    }
}
function initM3000() {
    Marquee3k.init({
        selector: 'marquee3k', // define a custom classname
    });
}

///////////////////// PAGE INDEX ONLY
function initScripts_Index() {
    for (let i = 0; i < document.querySelectorAll('.mini-thumbnail').length; i++) {
        const mini_thumbnail = document.querySelectorAll('.mini-thumbnail')[i];
        const to_scroll_to = mini_thumbnail.closest('.single-theme');
        mini_thumbnail.onclick = () => {
            var idx = mini_thumbnail.getAttribute('data-index');
            gsap.to(window, { duration: 0.8, ease: 'power2.inOut', scrollTo: { y: to_scroll_to.querySelector('.experiment-' + idx), offsetY: 150 } });
            console.log(idx);
        };
    }

    initM3000();
}
////////////////////////////////////////////////
